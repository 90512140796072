import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchInquiriesPermissions} from './queries/use-fetch-inquiries-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useNewInquiriesPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['inquiries']['header']['newInquiry'] & {loading: boolean} {
    const {data: permissionData, loading} = useFetchInquiriesPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.shouldQuery,
    });

    // cannot use context here as the context is being initialized
    const [{hideNewEnquiryButton}, loadingFeatures] = useStatFeatures({
        institutionId: payload.institutionSetId,
    });

    return React.useMemo(
        () => ({
            canCreate:
                (!hideNewEnquiryButton.isEnabled && permissionData?.attendance.management.orgInquiries[0].canCreate) ??
                false,
            canRead:
                (!hideNewEnquiryButton.isEnabled && permissionData?.attendance.management.orgInquiries[0].canCreate) ??
                false,
            canUpdate: false,
            canDelete: false,
            loading: loading || loadingFeatures,
        }),
        [hideNewEnquiryButton.isEnabled, permissionData?.attendance.management.orgInquiries, loading, loadingFeatures],
    );
}

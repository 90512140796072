import React from 'react';

import {useStatFeaturesQuery} from './use-stat-features-query';
import {toggleableFeatureAdapter} from './stat-togglable-feature-adapter';
import {type StatFeatures} from './stat-features';
import {type UseStatFeaturesPayload} from './use-stat-features-payload';

/**
 * @deprecated We agreed on using the stat permissions together with the StatGuard instead.
 * @deprecated Use the stat context instead
 */
export function useStatFeatures(payload: UseStatFeaturesPayload): [StatFeatures, boolean] {
    const {data, loading} = useStatFeaturesQuery({
        variables: {
            institutionSetId: payload.institutionId as string,
            siteSetId: payload.institutionId as string,
        },
        skip: payload.skip || !payload.institutionId,
    });

    return React.useMemo(() => {
        const {
            staffhours: {featureSettings: staffhoursFeatureSettings},
            attendance: {featureSettings: attendanceFeatureSettings},
        } = data ?? {
            staffhours: {featureSettings: {} as any},
            attendance: {featureSettings: {} as any},
        };

        return [
            {
                leave: toggleableFeatureAdapter(staffhoursFeatureSettings.leave),
                leaveRequests: toggleableFeatureAdapter(staffhoursFeatureSettings.leaveRequest),
                partDayLeave: toggleableFeatureAdapter(staffhoursFeatureSettings.partDayLeave),
                staffHours: toggleableFeatureAdapter(staffhoursFeatureSettings.staffHours),
                subtypes: toggleableFeatureAdapter(staffhoursFeatureSettings.subtype),
                leaveRange: toggleableFeatureAdapter(staffhoursFeatureSettings.leaveRangeSettings),
                holidayAllowance: toggleableFeatureAdapter(staffhoursFeatureSettings.holidayLeaveAllowance),
                staffSignInOut: toggleableFeatureAdapter(staffhoursFeatureSettings.employeeCheckIn),
                shiftPlanner: toggleableFeatureAdapter(staffhoursFeatureSettings.shiftplanner),
                staffLeaveApi: toggleableFeatureAdapter(staffhoursFeatureSettings.staffLeaveAPI),
                attendancePage: toggleableFeatureAdapter(staffhoursFeatureSettings.staffAttendancePage),
                subtypesRequired: toggleableFeatureAdapter({
                    canOptIn: staffhoursFeatureSettings.subtype?.canOptInToLeaveSubtypeRequired,
                    enabled: staffhoursFeatureSettings.subtype?.required,
                }),
                customFte: toggleableFeatureAdapter(attendanceFeatureSettings.customFte),
                milkReport: toggleableFeatureAdapter(attendanceFeatureSettings.milkReport),
                kinderConnect: toggleableFeatureAdapter(attendanceFeatureSettings.kinderConnect),
                customRegistrationForms: toggleableFeatureAdapter(attendanceFeatureSettings.customRegistrationForms),
                hideNewEnquiryButton: toggleableFeatureAdapter({
                    canEnable: false,
                    isEnabled: attendanceFeatureSettings.inquiry?.hideNewEnquiryButton,
                }),
                hideInquiryCSVDownloadFeature: toggleableFeatureAdapter({
                    canEnable: false,
                    isEnabled: attendanceFeatureSettings.inquiry?.hideEnquiryCSVDownload,
                }),
                orgLevelOccupancyReport: toggleableFeatureAdapter(attendanceFeatureSettings.orgOccupancy),
            },
            loading,
        ];
    }, [data, loading]);
}

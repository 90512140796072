import React from 'react';
import styled from 'styled-components';

import {Flex} from 'web-app/react/components/layout/layout';
import {EspiraIcon} from 'web-app/react/components/icons/custom';
import {FamlyLogoMark, FamlyLogoFullColoredBG} from 'web-app/react/components/icons/branding';
import {hexToRGBString} from 'web-app/util/hex-to-rgb';
import {negative, s1} from 'web-app/styleguide/spacing';
import {media} from 'web-app/styleguide/utils';
import {NewLookTheme} from 'web-app/styleguide/themes';
import EspiraTheme from 'web-app/styleguide/themes/espira';
import KidoTheme from 'web-app/styleguide/themes/kido';
import KidoLogo from 'root/public/img/kido/kido-transparent.png';
import BrightHorizonsTheme from 'web-app/styleguide/themes/bright-horizons';
import BrightHorizonsLogo from 'root/public/img/bright-horizons/logo.svg';
import KindredLogo from 'root/public/img/kindred/logo-colored-bg.svg';
import KindredTheme from 'web-app/styleguide/themes/kindred';
import MonkeyPuzzleLogo from 'root/public/img/monkeypuzzle/logo.svg';
import MonkeyPuzzleTheme from 'web-app/styleguide/themes/monkeypuzzle';
import GrandirUKLogo from 'root/public/img/grandiruk/GrandirLogoMark-DarkBG.svg';
import GrandirUKTheme from 'web-app/styleguide/themes/grandiruk';
import NuffieldHealthLogo from 'root/public/img/nuffieldhealth/NuffieldLogo-DarkBG.svg';
import NuffieldHealthTheme from 'web-app/styleguide/themes/nuffieldhealth';
import NFamilyClubLogo from 'root/public/img/nfamilyclub/logo-colored-bg.svg';
import NFamilyClubTheme from 'web-app/styleguide/themes/nfamilyclub';
import KinderzimmerUKLogo from 'root/public/img/kinderzimmeruk/KinderzimmerUKLogoMark.svg';
import KinderzimmerUKTheme from 'web-app/styleguide/themes/kinderzimmeruk';
import CpcTreehouseLogo from 'root/public/img/cpc-treehouse/CpcTreehouseLogoMark.svg';
import CpcTreehouseTheme from 'web-app/styleguide/themes/cpc-treehouse';
import CpcOrchardLogo from 'root/public/img/cpc-orchard/CpcOrchardLogoMark.svg';
import CpcOrchardsTheme from 'web-app/styleguide/themes/cpc-orchards';

import {type WhiteLabelConfiguration} from './types';

/**
 * Famly (default) configuration
 */
export const makeFamlyConfiguration = (): WhiteLabelConfiguration => {
    const theme = NewLookTheme;
    return {
        ProductName: 'Famly',
        MobileAppUrl: 'com.famly.famlyapp',
        PinFontColor: theme.chromeColor,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: `rgba(${hexToRGBString(theme.text)}, 0.5)`,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: theme.chromeColor,
        CircleAndDotColor: theme.primary,
        PinLogo: FamlyPinLogoComponent,
        LoginLogo: FamlyLoginLogoComponent,
        WithInstitutionImage: true,
    };
};

const FamlyPinLogoComponent = ({className}: {className?: string}) => (
    <Flex className={className} justify="center" align="center">
        {/* width={null} lets styled() override the prop */}
        <Flex align="center" marginLeft={negative(s1)}>
            <FamlyLogoFullColoredBG width={120} />
        </Flex>
    </Flex>
);

const FamlyLoginLogoComponent = () => {
    return <FamlyLogoMark width="120" />;
};

/**
 * Espira configuration
 */
export const makeEspiraConfiguration = (): WhiteLabelConfiguration => {
    const theme = EspiraTheme;
    return {
        ProductName: 'Spireportalen',
        MobileAppUrl: 'no.spireportalen.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.text,
        PrimaryBackgroundColor: '#669600',
        SecondaryBackgroundColor: '#80BC00',
        CircleAndDotColor: theme.primary,
        PinLogo: EspiraLogoComponent,
        LoginLogo: () => EspiraLogoComponent({isLogin: true}),
        WithInstitutionImage: false,
        p500: '#491315',
        p400: '#A2282F',
        p300: '#B82D35',
        p200: '#FDF7F7',
    };
};

const StyledEspiraSidebarIcon = styled(EspiraIcon)<{width?: number}>`
    height: 144px;
    width: ${props => `${props.width}px`};
    ${media.nonStandardTablet`
        height: 90px;
        width: 90px;
    `}
`;

const EspiraLogoComponent = ({className, isLogin}: {className?: string; isLogin?: boolean}) => (
    /**
     * The size, height, and width props are overwritten by styled components.
     * They're there to satisfy TS and to properly size the icon within its' viewbox.
     */
    <StyledEspiraSidebarIcon className={className} height={139} width={isLogin ? 144 : 60} />
);

/**
 * Kido configuration
 */
export const makeKidoConfiguration = (): WhiteLabelConfiguration => {
    const theme = KidoTheme;

    return {
        ProductName: 'Kido',
        MobileAppUrl: 'school.kido.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KidoLogoComponent,
        LoginLogo: KidoLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledKidoLogo = styled.img`
    height: 94px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const KidoLogoComponent = ({className}: {className?: string}) => {
    return <StyledKidoLogo className={className} src={KidoLogo} />;
};

/**
 * Bright Horizons configuration
 */
export const makeBrightHorizonsConfiguration = (): WhiteLabelConfiguration => {
    return {
        ProductName: 'Bright Horizons',
        MobileAppUrl: 'com.brighthorizons.app',
        PinFontColor: BrightHorizonsTheme.text,
        PinShadowColor: BrightHorizonsTheme.invertedText,
        PinPillColor: BrightHorizonsTheme.mf.colorPalette.p300,
        PinPillEmptyColor: BrightHorizonsTheme.invertedText,
        PrimaryColor: BrightHorizonsTheme.chromeColor,
        PrimaryBackgroundColor: '#1F3E4E',
        SecondaryBackgroundColor: '#004A61',
        CircleAndDotColor: BrightHorizonsTheme.chromeColor,
        PinLogo: BrightHorizonsPinLogoComponent,
        LoginLogo: BrightHorizonsLoginLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledBrightHorizonsPinLogo = styled.img`
    width: 168px;
    height: 64px;
`;

const BrightHorizonsPinLogoComponent = ({className}: {className?: string}) => {
    return <StyledBrightHorizonsPinLogo className={className} src={BrightHorizonsLogo} />;
};

const StyledBrightHorizonsLoginLogo = styled.img`
    width: 252px;
    height: 96px;
`;

const BrightHorizonsLoginLogoComponent = ({className}: {className?: string}) => {
    return <StyledBrightHorizonsLoginLogo className={className} src={BrightHorizonsLogo} />;
};

/**
 * Kindred configuration
 */
export const makeKindredConfiguration = (): WhiteLabelConfiguration => {
    const theme = KindredTheme;

    return {
        ProductName: 'Kindred',
        MobileAppUrl: 'com.kindred.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KindredLogoComponent,
        LoginLogo: KindredLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledKindredLogo = styled.img`
    height: 160px;
    width: 100%;

    ${media.nonStandardTablet`
        height: 100px;
    `}
`;

const KindredLogoComponent = ({className}: {className?: string}) => {
    return <StyledKindredLogo className={className} src={KindredLogo} />;
};

/**
 * MonkeyPuzzle configuration
 */
export const makeMonkeyPuzzleConfiguration = (): WhiteLabelConfiguration => {
    const theme = MonkeyPuzzleTheme;

    return {
        ProductName: 'Monkey Puzzle',
        MobileAppUrl: 'com.monkeypuzzle.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: MonkeyPuzzleLogoComponent,
        LoginLogo: MonkeyPuzzleLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledMonkeyPuzzleLogo = styled.img`
    height: 156px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const MonkeyPuzzleLogoComponent = ({className}: {className?: string}) => {
    return <StyledMonkeyPuzzleLogo className={className} src={MonkeyPuzzleLogo} />;
};

/**
 * Grandir UK configuration
 */
export const makeGrandirUKConfiguration = (): WhiteLabelConfiguration => {
    const theme = GrandirUKTheme;

    return {
        ProductName: 'Grandir UK',
        MobileAppUrl: 'com.grandiruk.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: GrandirUKLogoComponent,
        LoginLogo: GrandirUKLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledGrandirUKLogo = styled.img`
    width: 88px;
`;

const GrandirUKLogoComponent = ({className}: {className?: string}) => {
    return <StyledGrandirUKLogo className={className} src={GrandirUKLogo} />;
};

/**
 * Nuffield Health configuration
 */
export const makeNuffieldHealthConfiguration = (): WhiteLabelConfiguration => {
    const theme = NuffieldHealthTheme;

    return {
        ProductName: 'Nuffield Health Nursery',
        MobileAppUrl: 'com.nuffieldhealth.nursery',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: theme.chromeColor,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: NuffieldHealthLogoComponent,
        LoginLogo: NuffieldHealthLogoComponent,
        WithInstitutionImage: false,
        p500: '#003D00',
        p400: '#00A200',
        p300: '#00B200',
        p200: '#F5FFF5',
    };
};

const StyledNuffieldHealthLogo = styled.img`
    width: 260px;
    margin-bottom: 20px;
`;

const NuffieldHealthLogoComponent = ({className}: {className?: string}) => {
    return <StyledNuffieldHealthLogo className={className} src={NuffieldHealthLogo} />;
};

/**
 * NFamilyClub configuration
 */
export const makeNFamilyClubConfiguration = (): WhiteLabelConfiguration => {
    const theme = NFamilyClubTheme;

    return {
        ProductName: 'N Family Club',
        MobileAppUrl: 'com.nfamilyclub.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: NFamilyClubLogoComponent,
        LoginLogo: NFamilyClubLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledNFamilyClubLogo = styled.img`
    height: 128px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const NFamilyClubLogoComponent = ({className}: {className?: string}) => {
    return <StyledNFamilyClubLogo className={className} src={NFamilyClubLogo} />;
};

/**
 * KinderzimmerUK configuration
 */
export const makeKinderzimmerUKConfiguration = (): WhiteLabelConfiguration => {
    const theme = KinderzimmerUKTheme;

    return {
        ProductName: 'Kinderzimmer',
        MobileAppUrl: 'com.kinderzimmeruk.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: KinderzimmerUKLogoComponent,
        LoginLogo: KinderzimmerUKLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledKinderzimmerUKLogo = styled.img`
    height: 128px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const KinderzimmerUKLogoComponent = ({className}: {className?: string}) => {
    return <StyledKinderzimmerUKLogo className={className} src={KinderzimmerUKLogo} />;
};

/**
 * CPC Treehouse configuration
 */
export const makeCpcTreehouseConfiguration = (): WhiteLabelConfiguration => {
    const theme = CpcTreehouseTheme;

    return {
        ProductName: 'Treehouse Children’s Academy',
        MobileAppUrl: 'com.treehouseschools.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: CpcTreehouseLogoComponent,
        LoginLogo: CpcTreehouseLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledCpcTreehouseLogo = styled.img`
    height: 128px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const CpcTreehouseLogoComponent = ({className}: {className?: string}) => {
    return <StyledCpcTreehouseLogo className={className} src={CpcTreehouseLogo} />;
};

/**
 * CPC Orchard configuration
 */
export const makeOrchardConfiguration = (): WhiteLabelConfiguration => {
    const theme = CpcOrchardsTheme;

    return {
        ProductName: 'Orchard Academy',
        MobileAppUrl: 'com.childrensorchardacademy.app',
        PinFontColor: theme.text,
        PinShadowColor: theme.invertedText,
        PinPillColor: theme.mf.colorPalette.p300,
        PinPillEmptyColor: theme.invertedText,
        PrimaryColor: theme.chromeColor,
        PrimaryBackgroundColor: theme.chromeColor,
        SecondaryBackgroundColor: `rgba(${hexToRGBString(theme.chromeColor)}, 0.8)`,
        CircleAndDotColor: theme.chromeColor,
        PinLogo: CpcOrchardLogoComponent,
        LoginLogo: CpcOrchardLogoComponent,
        WithInstitutionImage: false,
    };
};

const StyledCpcOrchardLogo = styled.img`
    height: 228px;
    width: 224px;
    ${media.nonStandardTablet`
        height: 65px;
        width: 156px;
    `}
`;

const CpcOrchardLogoComponent = ({className}: {className?: string}) => {
    return <StyledCpcOrchardLogo className={className} src={CpcOrchardLogo} />;
};

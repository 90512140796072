import React from 'react';
import {Stack, Text, useBreakpoints, createStack} from 'modern-famly';
import styled from 'styled-components';

import {PersonImage, type ImageSize, IMAGE_PROPS} from 'signin-app/components/person-image';

export const StyledTextContainer = styled(createStack({}))`
    * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const SelectablePersonButton: React.FC<{
    image: string | undefined;
    initials: string;
    name: string;
    selected?: boolean;
    onClick?: VoidFunction;
    dataE2eClass?: string;
    size?: ImageSize;
}> = ({image, initials, name, selected, onClick, dataE2eClass, size}) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();

    // This makes sure the text doesn't go out of the Stack container,
    // but allows it to be fullwidth despite the padding on the main container
    const maxTextWidth = React.useMemo(() => {
        if (size) {
            const width = parseInt(IMAGE_PROPS[size].size.replace('px', ''), 10);
            return isTabletPortraitAndLarger ? `${width + 24 * 2 - 4}px` : `${width + 12 * 2 - 4}px`;
        }
        return '100%';
    }, [size, isTabletPortraitAndLarger]);

    return (
        <Stack
            direction="column"
            alignItems="center"
            px={{base: 3, tabletPortrait: 6}}
            py={{base: 2, tabletPortrait: 3}}
            borderWidth="1px"
            borderStyle="solid"
            borderRadius={{base: 3, tabletPortrait: 4}}
            cursor="pointer"
            // Changing the border color to the background color when selected to avoid jumping content
            // This would be more flexible if borderColor could be set to 'transparent'
            borderColor={selected ? 'p300' : 'n25'}
            backgroundColor={selected ? 'p100' : undefined}
            onClick={onClick}
            data-e2e-class={dataE2eClass}
            maxWidth={size ? IMAGE_PROPS[size].size : '100%'}
            overflowX="hidden"
        >
            <PersonImage image={image} initials={initials} size={size} />
            <StyledTextContainer>
                <Text
                    variant={size === 'sm' || size === 'md' ? 'h6' : 'h5'}
                    color="n400"
                    emphasized
                    mt={{base: 0.5, tabletPortrait: 1.5}}
                    maxWidth={maxTextWidth}
                >
                    {name}
                </Text>
            </StyledTextContainer>
        </Stack>
    );
};

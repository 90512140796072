import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useShiftPlannerPermissions} from './use-shift-planner-permissions';

export function useStaffRatioReportPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffRatioReport'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {
        shift: {published},
        loading,
    } = useShiftPlannerPermissions({
        institutionSetId,
        shouldQuery,
    });

    return React.useMemo(
        () => ({
            header: {},
            content: {
                scheduledStaff: {
                    ...published,
                    // In this scenario, the button's performs a "view" action (and not an "edit"/"update" action),
                    // so we map the "canUpdate" to the "canRead" permission accordingly.
                    canUpdate: published.canRead,
                },
            },
            loading,
        }),
        [published, loading],
    );
}

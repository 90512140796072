import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useInquiriesDownloadCsvPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['inquiries']['header']['downloadCsv'] & {loading: boolean} {
    const [statFeatures, loading] = useStatFeatures({
        institutionId: payload.institutionSetId,
    });

    return React.useMemo(
        () => ({
            canRead: !statFeatures.hideInquiryCSVDownloadFeature.isEnabled,
            canUpdate: statFeatures.hideInquiryCSVDownloadFeature.canEnable,
            canCreate: false,
            canDelete: false,
            loading,
        }),
        [
            statFeatures.hideInquiryCSVDownloadFeature.canEnable,
            statFeatures.hideInquiryCSVDownloadFeature.isEnabled,
            loading,
        ],
    );
}

import React from 'react';
import {createBox} from 'modern-famly';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';

import RoutesMap from 'signin-app/routes/routes-map';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const StyledBox = styled(createBox({}))<{customBackgroundColor?: string}>`
    background-color: ${props =>
        props.customBackgroundColor ? props.customBackgroundColor : props.theme.mf.colorPalette.n25};
    min-height: 100svh;
`;

export const AppContainer = ({children}) => {
    const location = useLocation();

    const path = React.useMemo(() => {
        return location.pathname;
    }, [location.pathname]);

    const p500 = useCustomWhitelabelColor('p500');
    const backgroundColor = path === '/' || path === RoutesMap.pin || path === RoutesMap.qr ? p500 : undefined;

    return <StyledBox customBackgroundColor={backgroundColor}>{children}</StyledBox>;
};

import {createStack} from 'modern-famly';
import styled from 'styled-components';

export const MIN_GRID_CARD_SIZE = 110;
export const GRID_ROW_GAP = 32;

export const GroupGrid = styled(
    createStack({
        display: 'grid',
        columnGap: 2,
        rowGap: GRID_ROW_GAP / 4,
        justifyContent: 'space-between',
    }),
)`
    grid-template-columns: repeat(auto-fill, minmax(${MIN_GRID_CARD_SIZE}px, 1fr));
    @media (max-width: 455px) {
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
    }
`;

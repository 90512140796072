import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useInquiriesDownloadCsvPermissions} from './use-inquiries-download-csv-permissions';
import {useNewInquiriesPermissions} from './use-new-inquiries-permissions';

export function useInquiriesPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['inquiries'] & {loading: boolean} {
    const {loading: loadingDownloadCsv, ...downloadCsv} = useInquiriesDownloadCsvPermissions(payload);
    const {loading: loadingNewInquiries, ...newInquiry} = useNewInquiriesPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingDownloadCsv || loadingNewInquiries,
            header: {
                downloadCsv,
                newInquiry,
            },
            content: {},
        }),
        [downloadCsv, loadingDownloadCsv, loadingNewInquiries, newInquiry],
    );
}

import React from 'react';

import {type StatCrudPermission} from './internal/stat-crud-permission';
import {type StatPermissions} from './internal/stat-permissions';
import {useAttendanceDetailsPermissions} from './internal/use-attendance-details-permissions';
import {useAttendancePermissions} from './internal/use-attendance-permissions';
import {useInstitutionPermissions} from './internal/use-institution-permissions';
import {useShiftPlannerPermissions} from './internal/use-shift-planner-permissions';
import {useStaffHoursPagePermissions} from './internal/use-staff-hours-page-permissions';
import {useStaffHoursPermissions} from './internal/use-staff-hours-permissions';
import {useStaffOverviewPermissions} from './internal/use-staff-overview-permissions';
import {useStaffPlannerPermissions} from './internal/use-staff-planner-permissions';
import {useStaffProfilePermissions} from './internal/use-staff-profile-permissions';
import {useWorkTagsPermissions} from './internal/use-work-tags-permissions';
import {useStaffHoursSettingsPagePermissions} from './internal/use-staff-hours-settings-page-permissions';
import {useCustomRegistrationFormsPermissions} from './internal/use-custom-registration-forms-permissions';
import {useFormsPermissions} from './internal/use-forms-permissions';
import {useSubtypePermissions} from './internal/use-subtype-permissions';
import {useStaffLeaveSettingsPermissions} from './internal/use-staff-leave-settings-permissions';
import {useChildAttendancePermissions} from './internal/use-child-attendance-permissions';
import {useInquiriesPermissions} from './internal/use-inquiries-permissions';
import {useStaffRatioReportPermissions} from './internal/use-staff-ratio-report-permissions';

export interface UseStatScopedPermissionPayload {
    institutionSetId: string;
    shouldQuery?: boolean;
    employeeId?: string;
    formId?: string;
}

export type StatPermissionScope =
    | 'attendance'
    | 'attendanceDetails'
    | 'workTags'
    | 'institution'
    | 'staffOverview'
    | 'shiftPlanner'
    | 'staffHours'
    | 'staffHoursPage'
    | 'staffProfile'
    | 'staffPlanner'
    | 'staffHoursSettingsPage'
    | 'customRegistrationForms'
    | 'forms'
    | 'subtypes'
    | 'staffLeaveSettings'
    | 'childAttendance'
    | 'inquiries'
    | 'staffRatioReport';

/**
 * Fetches, adapts and keeps all the available staffing and attendance permissions up to date and in sync.
 *
 * This is a work in progress and needs to be updated as we go
 *
 * @param payload option bag containing all necessary information to receive the available feature permissions.
 * @param scopes the scope array including all permissions to be fetched for the specific case where the context
 * using this hook is used
 * @returns the available stat permissions. If any given permission is not returned by the backend (null or undefined),
 * the return value will have it set to 'false', so not checks for `hasValue` or something is ever needed.
 *
 * In addition, the frontend interface for permissions is a simple `CRUD` interface, which is completely decoupled
 * from the backend structure, making later adaptations easier.
 *
 * @deprecated Do not use this directly. Only use stat context.
 */
export function useScopedStatPermissions(
    payload: Omit<UseStatScopedPermissionPayload, 'shouldQuery'>,
    scopes: StatPermissionScope[],
): Partial<StatPermissions> & {loading: boolean} {
    const {loading: shiftPlannerLoading, ...shiftPlanner} = useShiftPlannerPermissions({
        ...payload,
        shouldQuery: scopes.includes('shiftPlanner'),
    });
    const {loading: attendanceLoading, ...attendance} = useAttendancePermissions({
        ...payload,
        shouldQuery: scopes.includes('attendance'),
    });
    const {loading: attendanceDetailsLoading, ...attendanceDetails} = useAttendanceDetailsPermissions({
        ...payload,
        shouldQuery: scopes.includes('attendanceDetails'),
    });
    const {loading: workTagsLoading, ...workTags} = useWorkTagsPermissions({
        ...payload,
        shouldQuery: scopes.includes('workTags'),
    });
    const {loading: institutionsLoading, ...institution} = useInstitutionPermissions({
        ...payload,
        shouldQuery: scopes.includes('institution'),
    });
    const {loading: staffOverviewLoading, ...staffOverview} = useStaffOverviewPermissions({
        ...payload,
        shouldQuery: scopes.includes('staffOverview'),
    });
    const {loading: staffPlannerLoading, ...staffPlanner} = useStaffPlannerPermissions({
        ...payload,
        shouldQuery: scopes.includes('staffPlanner'),
    });
    const {loading: staffHoursLoading, ...staffHours} = useStaffHoursPermissions({
        ...payload,
        shouldQuery: scopes.includes('staffHours'),
    });
    const {loading: staffHoursPageLoading, ...staffHoursPage} = useStaffHoursPagePermissions({
        ...payload,
        shouldQuery: scopes.includes('staffHoursPage'),
    });
    const {loading: staffProfileLoading, ...staffProfile} = useStaffProfilePermissions({
        ...payload,
        shouldQuery: scopes.includes('staffProfile'),
    });
    const {loading: staffHoursSettingsPageLoading, ...staffHoursSettingsPage} = useStaffHoursSettingsPagePermissions({
        ...payload,
        shouldQuery: scopes.includes('staffHoursSettingsPage'),
    });
    const {loading: customRegistrationFormsLoading, ...customRegistrationForms} = useCustomRegistrationFormsPermissions(
        {
            ...payload,
            shouldQuery: scopes.includes('customRegistrationForms'),
        },
    );
    const {loading: formsLoading, ...forms} = useFormsPermissions({
        ...payload,
        shouldQuery: scopes.includes('forms'),
    });
    const {loading: subtypesLoading, ...subtypes} = useSubtypePermissions({
        ...payload,
        shouldQuery: scopes.includes('subtypes'),
    });
    const {loading: staffLeaveSettingsLoading, ...staffLeaveSettings} = useStaffLeaveSettingsPermissions({
        ...payload,
        shouldQuery: scopes.includes('staffLeaveSettings'),
    });
    const {loading: childAttendanceLoading, ...childAttendance} = useChildAttendancePermissions({
        ...payload,
        shouldQuery: scopes.includes('childAttendance'),
    });
    const {loading: inquiriesLoading, ...inquiries} = useInquiriesPermissions({
        ...payload,
        shouldQuery: scopes.includes('inquiries'),
    });
    const {loading: staffRatioReportLoading, ...staffRatioReport} = useStaffRatioReportPermissions({
        ...payload,
        shouldQuery: scopes.includes('staffRatioReport'),
    });

    return React.useMemo(
        () => ({
            loading:
                attendanceDetailsLoading ||
                workTagsLoading ||
                institutionsLoading ||
                shiftPlannerLoading ||
                staffHoursLoading ||
                staffHoursPageLoading ||
                staffProfileLoading ||
                staffPlannerLoading ||
                staffHoursSettingsPageLoading ||
                customRegistrationFormsLoading ||
                formsLoading ||
                subtypesLoading ||
                staffLeaveSettingsLoading ||
                childAttendanceLoading ||
                inquiriesLoading ||
                staffRatioReportLoading,
            staffHours,
            staffHoursPage,
            staffProfile,
            shiftPlanner,
            staffPlanner,
            unrestricted: unrestrictedCRUD,
            staffOverview,
            attendance,
            attendanceDetails,
            workTags,
            institution,
            staffHoursSettingsPage,
            customRegistrationForms,
            forms,
            subtypes,
            staffLeaveSettings,
            childAttendance,
            inquiries,
            staffRatioReport,
        }),
        [
            attendanceDetailsLoading,
            workTagsLoading,
            institutionsLoading,
            shiftPlannerLoading,
            staffHoursLoading,
            staffHoursPageLoading,
            staffProfileLoading,
            staffPlannerLoading,
            staffHoursSettingsPageLoading,
            customRegistrationFormsLoading,
            formsLoading,
            subtypesLoading,
            staffLeaveSettingsLoading,
            childAttendanceLoading,
            inquiriesLoading,
            staffRatioReportLoading,
            staffHours,
            staffHoursPage,
            staffProfile,
            shiftPlanner,
            staffPlanner,
            staffOverview,
            attendance,
            attendanceDetails,
            workTags,
            institution,
            staffHoursSettingsPage,
            customRegistrationForms,
            forms,
            subtypes,
            staffLeaveSettings,
            childAttendance,
            inquiries,
            staffRatioReport,
        ],
    );
}

const unrestrictedCRUD: StatCrudPermission = {
    canCreate: true,
    canRead: true,
    canUpdate: true,
    canDelete: true,
};

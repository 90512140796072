import {createBox} from 'modern-famly';
import styled from 'styled-components';

export const PageContainer = createBox({padding: {base: '32px 16px 48px', tabletPortrait: '40px 40px 120px'}});

export const StyledPageContainer = styled(PageContainer)`
    box-sizing: border-box;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
`;

export const HeaderLogoContainer = styled(createBox({}))`
    * {
        max-height: 32px;
    }
    svg,
    img {
        width: auto;
    }
`;

export const ENABLE_SAFEGUARDING_PAYLOAD_KEY = 'enableSafeguarding';
export const ENABLE_MEDICATION_FORMS_PAYLOAD_KEY = 'enableMedicationForms';
export const ENABLE_IMMUNIZATION_PAYLOAD_KEY = 'enableImmunization';
export const ENABLE_HEADCOUNTS_PAYLOAD_KEY = 'enableHeadcounts';

/**
 * TODO: This list should be enhanced with more sidebar items
 */
export enum SidebarItem {
    GetStarted = 'GetStarted',
    NewsFeed = 'NewsFeed',
    Home = 'Home',
    FeatureStore = 'FeatureStore',
    CustomForms = 'CustomForms',
    InquiriesPage = 'InquiriesPage',
    ShiftPlanner = 'ShiftPlanner',
    StaffHoursPage = 'StaffHoursPage',
    KinderConnect = 'KinderConnect',
    Reports = 'Reports',
    StaffAttendancePage = 'StaffAttendancePage',
    FinanceReports = 'FinanceReports',
    AttendanceReports = 'AttendanceReports',
    LearningReports = 'LearningReports',
    FinanceLandingPage = 'FinanceLandingPage',
    AttendanceLandingPage = 'AttendanceLandingPage',
    LearningLandingPage = 'LearningLandingPage',
    ChildrenLandingPage = 'ChildrenLandingPage',
    StaffRatioReport = 'StaffRatioReport',
    StaffLeavePage = 'StaffLeavePage',
    Templates = 'Templates',
}

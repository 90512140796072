import * as React from 'react';

import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {useTypedSelector} from 'signin-app/components/hooks';
import {getInitials} from 'signin-app/util/get-initials';

import * as Selectors from './selectors';

type CheckOutChildType = RecordInstance<IChild> | PinChild;

const CheckOutChildContext = React.createContext<{child: CheckOutChildType; displayName: string; initials: string}>(
    null!,
);

type CheckOutChildProviderProps = {children: React.ReactNode; child: CheckOutChildType};

function CheckOutChildProvider({children, child}: CheckOutChildProviderProps) {
    const isDataLimited = useTypedSelector(Selectors.limitedData);

    const initials = React.useMemo(
        () => getInitials(isDataLimited ? child.name.firstName : child.name.fullName),
        [child.name.firstName, child.name.fullName, isDataLimited],
    );
    const displayName = React.useMemo(
        () => (isDataLimited ? child.name.firstName : `${child.name.firstName} ${child.name.lastName.charAt(0)}.`),
        [child.name.firstName, child.name.lastName, isDataLimited],
    );

    return (
        <CheckOutChildContext.Provider value={{child, displayName, initials}}>{children}</CheckOutChildContext.Provider>
    );
}

function useCheckOutChild() {
    const context = React.useContext(CheckOutChildContext);

    if (context === undefined) {
        throw new Error('useCheckOutChild must be used within a CheckOutChildProvider');
    }

    return context;
}

export {CheckOutChildProvider, useCheckOutChild};

import {Stack} from 'modern-famly';
/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';
import i18next from 'i18next';
import styled, {css} from 'styled-components';

import Spinner from 'web-app/react/components/loading/spinner/spinner';
import Link, {type LinkProps} from 'web-app/react/components/link/link';
import Add from 'web-app/react/components/icons/legacy/lazy-icons/add';
import Trashcan from 'web-app/react/components/icons/legacy/lazy-icons/trash-can';
import More from 'web-app/react/components/icons/legacy/lazy-icons/more';
import {type BaseProps} from 'web-app/react/components/layout/layout';
import {Body} from 'web-app/react/components/text/text';
import {addHover} from 'web-app/styleguide/utils';

const getSpinnerSize = (spinnerSize?: number, buttonSize?: ButtonSize): number => {
    if (!spinnerSize) {
        return buttonSize === ButtonSize.mini ? 15 : 30;
    }
    return spinnerSize;
};

const StyledTrashcan = styled(Trashcan)`
    margin-right: 4px;
`;

const StyledAdd = styled(Add).attrs(props => ({
    fill: props.theme.accent1,
}))`
    margin-right: 6px;
`;

export enum ButtonAppearance {
    default = 'default',
    confirm = 'confirm',
    secondary = 'secondary',
    danger = 'danger',
    add = 'add',
    delete = 'delete',
    deleteNoText = 'deleteNoText',
    icon = 'icon',
    custom = 'custom',
    link = 'link',
    more = 'more',
    none = 'none',
    x = 'x',
    primary = 'primary',
    profile = 'profile',
    asDefault = 'asDefault',
    textSecondary = 'textSecondary',
    silent = 'silent',
}

export enum ButtonType {
    button = 'button',
    submit = 'submit',
    link = 'link',
}

export enum ButtonSize {
    mini = 'mini',
    small = 'small',
    medium = 'medium',
    big = 'big',
    auto = 'auto',
}

export enum ButtonPosition {
    left = 'left',
    right = 'right',
    fill = 'fill',
}

export enum Box {
    block = 'block',
    inline = 'inline',
}

export enum ButtonOpacity {
    transparent = 'transparent',
    fade = 'fade',
}

interface InternalProps {
    type?: any;
    spinnerId?: string;

    // State related propTypes
    isDisabled?: boolean;
    disabled?: boolean;

    // Style related propTypes
    appearance?: ButtonAppearance | any;
    opacity?: ButtonOpacity | any;
    buttonSize?: ButtonSize;
    position?: ButtonPosition | any;
    box?: Box | any;
    className?: string;
    promiseOnClick?: boolean;

    // Interaction related propTypes
    onClick?: any;
    onMouseDown?: (e: React.SyntheticEvent<HTMLElement>) => void;
    onMouseUp?: (e: React.SyntheticEvent<HTMLElement>) => void;
    onMouseEnter?: (e: React.SyntheticEvent<HTMLElement>) => void;
    onMouseLeave?: (e: React.SyntheticEvent<HTMLElement>) => void;

    // Contents of the Button
    spinner?: boolean;
    spinnerSize?: number;
    showAlert?: boolean;
}

export type ButtonProps = InternalProps & Partial<DefaultProps> & LinkProps & BaseProps;

type DefaultProps = Readonly<typeof defaultProps> & {
    onTouchEnd: (event: React.TouchEvent<HTMLDivElement>) => void;
};

const defaultProps = {
    type: 'button',
    spinner: false,
};

export const options = {
    appearance: ButtonAppearance,
    box: Box,
    opacity: ButtonOpacity,
    position: ButtonPosition,
    type: ButtonType,
    buttonSize: ButtonSize,
};

interface StyledLinkProps {
    isCustomStyle: any;
    isNoneStyle: any;
    buttonSize?: ButtonSize;
    position: ButtonPosition;
    box: Box;
    opacity: ButtonOpacity;
    appearance: ButtonAppearance;
    type?: string;
    disabled: boolean;
    href?: LinkProps['href'];
}

export const StyledButtonCSS = css`
    border-radius: 3px;
    margin: 0;
    padding: 4px 16px;
    line-height: 30px;
    text-align: center;
    border: 1px solid ${props => props.theme.delimiter};
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${props => props.theme.invertedText};
    color: ${props => props.theme.text};
    outline: none;
    display: inline-block;
    vertical-align: middle;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};

    /* State */
    ${addHover(css`
        &:hover:not([disabled]) {
            filter: brightness(97%);
            background-color: ${props => props.theme.backgroundHover};
        }
    `)}

    &:active:not([disabled]) {
        filter: brightness(97%);
    }

    &:focus {
        border: 1px solid ${props => props.theme.textSecondary};
        outline: none;
    }

    &[disabled] {
        opacity: 0.4;
        cursor: default;
    }
`;

export const PrimaryButtonCSS = css`
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    color: ${props => props.theme.invertedText};
    font-weight: 500;
    &:focus:not([disabled]) {
        background-color: ${props => props.theme.active};
        border-color: ${props => props.theme.primary};
    }
    ${addHover(css`
        &:hover:not([disabled]) {
            background-color: ${props => props.theme.active};
            border-color: ${props => props.theme.active};
        }
    `)}
`;

export const StyledButton = styled.div<StyledLinkProps>`
    ${props => {
        return (
            !props.isCustomStyle &&
            css`
                ${StyledButtonCSS}
                ${props.appearance !== ButtonAppearance.icon &&
                css`
                    svg {
                        vertical-align: text-top;
                    }
                `}
            `
        );
    }}

    /* Appearance specific styling */
    ${props => {
        switch (props.appearance) {
            case ButtonAppearance.confirm:
                return PrimaryButtonCSS;
            case ButtonAppearance.profile:
                return css`
                    box-shadow: 0 0 1px rgba(0, 0, 0, 0.11), 0 1px 2px rgba(0, 0, 0, 0.11);
                    border: 0;
                    border-right: 1px solid ${props => props.theme.delimiter};

                    &:focus:not([disabled]) {
                        border: inherit;
                        border-right: 1px solid ${props => props.theme.delimiter};
                    }
                `;
            case ButtonAppearance.icon:
                return css`
                    background-color: transparent;
                    border: 1px solid transparent;
                    ${addHover(css`
                        &:hover:not([disabled]) {
                            background-color: transparent;
                            border-color: transparent;
                            filter: brightness(97%);
                        }
                    `)}
                    &:focus:not([disabled]) {
                        background-color: transparent;
                        border-color: transparent;
                        filter: brightness(97%);
                    }
                    && {
                        line-height: 0;
                    }

                    ${props.buttonSize === ButtonSize.mini &&
                    css`
                        padding: 8px;
                    `}
                `;
            case ButtonAppearance.more:
                return css`
                    &,
                    &:focus,
                    &:active,
                    &:hover,
                    &&:focus:not(:active) {
                        border-color: transparent;
                    }
                    ${props.buttonSize === ButtonSize.mini &&
                    css`
                        padding: 8px;
                    `}
                `;
            case ButtonAppearance.link:
            case ButtonAppearance.silent:
                return css`
                    text-decoration: none;
                    color: ${() => {
                        switch (props.appearance) {
                            case ButtonAppearance.silent:
                                return props.theme.textDisabled;
                            default:
                                return props.theme.analogue2;
                        }
                    }};
                    padding: 0;
                    line-height: normal;
                    border: none;
                    background-color: transparent;
                    ${props.appearance === ButtonAppearance.silent
                        ? css`
                              text-decoration: underline;
                          `
                        : ''}

                    &,
                    &:focus,
                    &:active,
                    &:hover,
                    &:hover:not([disabled]),
                    &:focus:not(:active) {
                        background-color: transparent;
                        border-color: transparent;
                        border: none;
                    }
                `;
            case ButtonAppearance.secondary:
                return css`
                    background: ${props => props.theme.background};
                    border: 1px solid ${props => props.theme.delimiter};
                    padding: 2px 16px;
                    color: ${props => props.theme.accent1};
                    font-weight: 600;
                `;
            case ButtonAppearance.textSecondary:
                return css`
                    color: ${props => props.theme.textSecondary};
                `;
            case ButtonAppearance.add:
                return css`
                    &,
                    &:focus,
                    &:active,
                    &:hover,
                    &:focus:not(:active) {
                        border-color: transparent;
                    }
                `;
            default:
                return '';
        }
    }}

    /* Styling excluded by appearance */
    ${props =>
        props.appearance === ButtonAppearance.none || props.appearance === ButtonAppearance.asDefault
            ? ''
            : css`
                  &[type='submit'] {
                      ${PrimaryButtonCSS}
                  }
              `}
    ${props =>
        props.appearance === ButtonAppearance.none || props.appearance === ButtonAppearance.icon
            ? ''
            : css`
                  &:focus:not(:active) {
                      border-color: ${props => props.theme.borderConfiguration.focus};
                  }
              `}

    /* Opacity */
    ${props => {
        switch (props.opacity) {
            case ButtonOpacity.transparent:
                return css`
                    background-color: ${props => props.theme.invertedText};
                    border: 1px solid ${props => props.theme.delimiter};
                    color: ${props => props.theme.textSecondary};
                `;
            case ButtonOpacity.fade:
                return css`
                    opacity: 0.6;
                `;
        }
    }}

    /* Size */
    ${props => {
        switch (props.buttonSize) {
            case ButtonSize.mini:
                return css`
                    padding: 6px 10px;
                    line-height: 20px;
                    border-radius: 4px;
                `;
            case ButtonSize.small:
                return css`
                    padding: 10px 10px;
                    line-height: 20px;
                    border-radius: 4px;
                `;
            case ButtonSize.medium:
                return css`
                    padding: 8px 16px;
                    line-height: 21px;
                `;
            case ButtonSize.big:
                return css`
                    padding: 20px 25px;
                    line-height: 25px;
                `;
            case ButtonSize.auto:
                return css`
                    width: auto;
                `;
        }
    }}

    /* Position */
    ${props => {
        switch (props.position) {
            case ButtonPosition.left:
                return css`
                    float: left;
                    width: auto;
                `;
            case ButtonPosition.right:
                return css`
                    float: right;
                    width: auto;

                    & + button,
                    & + a {
                        ${props.position === ButtonPosition.right &&
                        css`
                            margin-left: 0;
                            margin-right: 8px;
                        `}
                    }
                `;
            case ButtonPosition.fill:
                return css`
                    width: 100%;
                `;
        }
    }}

    /* Box */
    ${props =>
        props.box === Box.inline &&
        css`
            display: inline-block;
            width: auto;

            & + button,
            & + a {
                margin-left: 8px;
            }
        `}

    ${props =>
        props.isNoneStyle &&
        css`
            padding: 0;
            border: none;
            line-height: inherit;
            border-radius: 0;
            text-align: inherit;
            background: none;

            &:focus {
                border: none;
                outline: none;
            }
        `}

    /* Align icons */
    > img {
        vertical-align: middle;
    }
`;

const WarningImage = styled.img`
    width: 19px;
    height: 15px;
    margin-right: 4px;
    margin-top: -2px;
`;

const StyledSpinner = styled(Spinner)`
    vertical-align: middle;
`;

class ButtonClass extends React.Component<React.PropsWithChildren<ButtonProps>, {promiseLoading: boolean}> {
    public static defaultProps = defaultProps;

    public static options = options;
    private _isMounted = true;

    constructor(props) {
        super(props);

        this.state = {
            promiseLoading: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        const {
            children,
            className,
            disabled,
            isDisabled,
            onMouseDown,
            onMouseUp,
            onMouseEnter,
            onMouseLeave,
            appearance,
            buttonSize,
            position,
            opacity,
            spinner,
            spinnerSize,
            box,
            type,
            showAlert,
            spinnerId,
            href,
            ...directProps
        } = this.props;

        // We don't want onClick or promiseOnClick to be passed to the button element
        const {onClick, promiseOnClick, ...filteredDirectProps} = directProps;

        const {promiseLoading} = this.state;

        const isNoneStyle = appearance === 'none';
        const isCustomStyle = appearance === 'custom' || isNoneStyle;

        const attributes = {
            className,
            onClick: this.handleClick,
            onMouseDown,
            onMouseUp,
            onMouseEnter,
            onMouseLeave,
            disabled: '',
        };

        // If spinner is true, we disable the button unless isDisabled or disabled is explicitly set to false
        const shouldDisableButton = Boolean(
            disabled || isDisabled || promiseLoading || (spinner && !(isDisabled === false || disabled === false)),
        );
        if (shouldDisableButton) {
            attributes.disabled = 'disabled';
        }

        let childElement =
            isCustomStyle || !showAlert ? (
                children
            ) : (
                <div>
                    {showAlert ? (
                        <WarningImage src="img/icons/warning-sign.png" alt={i18next.t('notices.warning')} />
                    ) : null}
                    {children}
                </div>
            );

        if (appearance === 'delete') {
            childElement = (
                <Stack alignItems="center">
                    <StyledTrashcan />
                    <span>{children ? children : i18next.t('delete')}</span>
                </Stack>
            );
        }
        if (appearance === 'add') {
            childElement = (
                <div>
                    <StyledAdd size={20} />
                    <Body as="span" inline>
                        {children ? children : i18next.t('add')}
                    </Body>
                </div>
            );
        }

        if (appearance === 'deleteNoText') {
            childElement = (
                <div>
                    <Trashcan />
                </div>
            );
        }

        if (appearance === 'more') {
            childElement = <More size={20} />;
        }

        if (appearance === 'x') {
            childElement = <span>×</span>;
        }

        if (spinner || promiseLoading) {
            const spinnerColor = ['confirm'].includes(appearance as string) ? 'white' : 'black';
            childElement = (
                <StyledSpinner
                    id={spinnerId}
                    color={spinnerColor}
                    size={getSpinnerSize(spinnerSize, buttonSize)}
                    inline={true}
                />
            );
        }

        if (type === ButtonType.link) {
            return (
                <StyledButton
                    as={Link as any}
                    {...attributes}
                    {...filteredDirectProps}
                    href={shouldDisableButton ? undefined : href}
                    isCustomStyle={isCustomStyle}
                    disabled={shouldDisableButton}
                    isNoneStyle={isNoneStyle}
                    buttonSize={buttonSize}
                    position={position}
                    box={box}
                    opacity={opacity}
                    appearance={appearance}
                >
                    {childElement}
                </StyledButton>
            );
        }

        return (
            <StyledButton
                as="button"
                type={type}
                role="button"
                {...attributes}
                {...filteredDirectProps}
                disabled={shouldDisableButton}
                isCustomStyle={isCustomStyle}
                isNoneStyle={isNoneStyle}
                buttonSize={buttonSize}
                position={position}
                box={box}
                opacity={opacity}
                appearance={appearance}
            >
                {childElement}
            </StyledButton>
        );
    }

    public componentWillUnmount() {
        // We do this to make sure we don't update state on an unmounted button if promiseOnClick is set
        this._isMounted = false;
    }

    private handleClick(...args) {
        if (!this.props.onClick) {
            return;
        }

        const onClickReturnValue = this.props.onClick(...args);

        if (this.props.promiseOnClick && onClickReturnValue && typeof onClickReturnValue.finally === 'function') {
            this.setState({
                promiseLoading: true,
            });
            onClickReturnValue.finally(() => {
                if (this._isMounted) {
                    this.setState({
                        promiseLoading: false,
                    });
                }
            });
        }
    }
}

/**
 * Export button as styled component
 *
 * @deprecated Prefer using the button component from Modern Famly
 *             or button-v2 (app/react/components/button-v2) if possible
 */
export default styled(ButtonClass)<ButtonProps>``;

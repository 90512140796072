import React from 'react';
import {List} from 'immutable';

import {type IEmployee} from 'signin-app/entities/staff/model';
import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild, type PinEmployee} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import generateUUID from 'web-app/util/uuid';
import {GRID_ROW_GAP, MIN_GRID_CARD_SIZE} from 'signin-app/components/group-grid';

// Fixed by design, but not defined in CSS
const PERSON_BUTTON_CARD_HEIGHT = 136;
const GRID_COLUMN_GAP = 4;

export const useElementDimensions = () => {
    const [dimensions, setDimensions] = React.useState({
        width: 0,
        height: 0,
    });

    const previousObserver = React.useRef<ResizeObserver | null>(null);

    const ref = React.useCallback((node: SVGElement | HTMLElement | null) => {
        if (previousObserver.current) {
            previousObserver.current.disconnect();
            previousObserver.current = null;
        }

        if (node?.nodeType === Node.ELEMENT_NODE) {
            const observer = new ResizeObserver(([entry]) => {
                if (entry && entry.borderBoxSize) {
                    const {inlineSize: width, blockSize: height} = entry.borderBoxSize[0];

                    setDimensions({width, height});
                }
            });

            observer.observe(node);
            previousObserver.current = observer;
        }
    }, []);

    return {ref, dimensions};
};

type Props = {
    items: List<
        RecordInstance<IChild> | RecordInstance<PinChild> | RecordInstance<IEmployee> | RecordInstance<PinEmployee>
    >;
    width: number;
    height: number;
};

export const useGetPages = (props: Props) => {
    const {items, width, height} = props;

    const pages = React.useMemo(() => {
        const numColumns = Math.floor(width / (MIN_GRID_CARD_SIZE + GRID_COLUMN_GAP));
        const numRows = Math.floor(height / (PERSON_BUTTON_CARD_HEIGHT + GRID_ROW_GAP));
        const maxNumOfCards = numColumns * numRows;
        const maxLength = Math.ceil(items.size / maxNumOfCards);
        return maxNumOfCards
            ? Array.from({length: maxLength}, (_, index) => {
                  const pageId = generateUUID();
                  const _items = List(items.slice(index * maxNumOfCards, (index + 1) * maxNumOfCards));

                  return {
                      pageId,
                      items: _items,
                  };
              })
            : [];
    }, [height, items, width]);

    return {
        pages,
    };
};
